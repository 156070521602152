export interface IProfile {
  userId: string;
  emailAddress: string;
  businessName: string;
  firstName: string;
  lastName: string;
  shouldReceiveMarketingEmails: boolean;
}

export interface IProfileDb {
  businessName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  shouldReceiveMarketingEmails: boolean;
}

export class ProfileModel implements IProfile, IProfileDb {
  public userId: string;
  public businessName: string;
  public firstName: string;
  public lastName: string;
  public emailAddress: string;
  public shouldReceiveMarketingEmails: boolean;

  constructor({
    userId,
    businessName,
    firstName,
    lastName,
    emailAddress,
    shouldReceiveMarketingEmails,
  }: Partial<IProfile> = {}) {
    this.userId = userId!;
    this.emailAddress = emailAddress!;
    this.businessName = businessName || '';
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.shouldReceiveMarketingEmails =
      typeof shouldReceiveMarketingEmails !== 'undefined'
        ? shouldReceiveMarketingEmails
        : true;
  }
}
