import { ServiceModel, SettingsModel } from './models';

export interface IImage {
  url: string;
  ref: string;
}

export interface IMachine {
  $key?: string;
  id?: string;
  _id: string; // old db
  name: string;
  lastServiced: string | null;
  lastMKH: number;
  serviceItems: Array<ServiceModel>; // []{ name, dateSeviced, serviceMKH, isWarning }
  serviceMonths: number; // service every n number of months
  serviceMKH: number; // service every n number of miles, kilometers or hours
  purchasePrice: number | string;
  purchasePlace: string;
  purchaseDate: string | null;
  originalMKH: number;
  mfgYear: number | null;
  serialNumber: string;
  other: string;
  notes: string;
  /** @deprecated */
  image: string | null;
  images: Record<string, IImage>;
  isWarning: boolean;
  dateAdded: string;
  displayName: string;
  customServiceItems?: Array<ServiceModel>;
  dtstamp: number;
  duid: string;
  oid: string;
  isArchived?: boolean;
}

export class MachineModel implements IMachine {
  _id: string;
  name: string;
  lastServiced: string | null = null;
  lastMKH: number;
  serviceItems: Array<ServiceModel>; // []{ name, dateSeviced, serviceMKH, isWarning }
  serviceMonths: number; // service every n number of months
  serviceMKH: number; // service every n number of miles, kilometers or hours
  purchasePrice: string | number;
  purchasePlace: string;
  purchaseDate: string | null = null;
  originalMKH: number;
  mfgYear: number | null;
  serialNumber: string;
  other: string;
  notes: string;
  /** @deprecated */
  image: string | null = null;
  images: Record<string, IImage> = {};
  isWarning: boolean;
  customServiceItems: Array<ServiceModel>;
  dateAdded: string;
  displayName: string;
  dtstamp: number;
  duid: string = '';
  oid: string = '';
  isArchived: boolean = false;

  constructor(opt?: Partial<IMachine>) {
    const settings: SettingsModel = new SettingsModel();
    const defaultServices = settings.availServices.map((x, idx) => {
      return new ServiceModel({ name: x, checked: idx < 3 });
    });

    this.dtstamp = Date.now();

    const today = new Date();
    this._id = opt?._id ?? '';
    this.name = opt?.name ?? '';
    this.lastServiced = opt?.lastServiced ?? null;
    this.lastMKH = opt?.lastMKH ?? 0;
    this.serviceItems = opt?.serviceItems ?? defaultServices;
    this.serviceMonths = opt?.serviceMonths ?? 6;
    this.serviceMKH = opt?.serviceMKH ?? 0;
    this.purchasePrice = opt?.purchasePrice ?? '0';
    this.purchasePlace = opt?.purchasePlace ?? '';
    this.purchaseDate = opt?.purchaseDate ?? null;
    this.originalMKH = opt?.originalMKH ?? 0;
    this.mfgYear = opt?.mfgYear ?? null;
    this.serialNumber = opt?.serialNumber ?? '';
    this.other = opt?.other ?? '';
    this.notes = opt?.notes ?? '';
    // eslint-disable-next-line deprecation/deprecation
    this.image = opt?.image ?? '';
    this.images = opt?.images ?? {};
    this.isWarning = opt?.isWarning ?? false;
    // Create a copy of the customServiceItems array
    this.customServiceItems = (opt?.customServiceItems ?? []).concat();
    this.dateAdded = opt?.dateAdded ?? today.toISOString();
    this.displayName = opt?.displayName ?? this.name;
    this.isArchived = opt?.isArchived ?? false;
  }

  get allServiceItems(): Array<ServiceModel> {
    return this.serviceItems.concat(this.customServiceItems);
  }

  get allCheckedServiceItems(): Array<ServiceModel> {
    return this.allServiceItems.filter((x) => x.checked);
  }

  get mainImage(): IImage | null {
    const images = Object.values(this.images);
    return images.length > 0 ? images[0] : null;
  }

  get mainImageUrl(): string {
    const mainImage = this.mainImage;
    return this.mainImage ? this.mainImage.url : 'assets/add-photo.png';
  }

  get imageCount(): number {
    return Object.keys(this.images).length;
  }
}
