import { differenceInMonths, parseISO, isValid } from 'date-fns';

export interface IServiceUpdate {
  id?: string;
  /**
   * This was the machine name in the old database.
   * and caused issues when the machine name was changed.
   * @deprecated
   */
  machine: string;
  machineId: string | undefined;
  service: string;
  dateAdded: string;
  dateServiced: string;
  serviceMKH: number; // miles, kilometers, hours
  primaryPart: string;
  secondaryPart: string;
  thirdPart: string;
  brand: string;
  updatedBy: string;
  notes: string;
  dtstamp: number;
  lat: number;
  long: number;
  duid: string;
  oid: string;
}

export interface IService {
  name: string;
  dateServiced: string;
  serviceMKH: number;
  checked: boolean;
  everyMKH: number;
  everyMonths: number;
  dtstamp: number;
}

export interface IServiceEdit {
  everyMKH: number;
  everyMonths: number;
  dtstamp: number;
}

export class ServiceModel implements IService {
  name: string;
  dateServiced: string;
  serviceMKH: number; // miles, kilometers, hours
  checked: boolean;
  everyMKH: number;
  everyMonths: number;
  dtstamp: number;

  constructor(opt?: any) {
    this.name = (opt && opt.name) || '';
    this.dateServiced = (opt && opt.dateServiced) || '';
    this.serviceMKH = (opt && opt.serviceMKH) || 0;
    this.checked = (opt && opt.checked) || false;
    this.everyMKH = (opt && opt.everyMKH) || 500;
    this.everyMonths = (opt && opt.everyMonths) || 6;
    this.dtstamp = Date.now();
  }
}

export class ServiceUpdateModel implements IServiceUpdate {
  id?: string;
  machine: string;
  machineId: string | undefined;
  service: string;
  dateAdded: string;
  dateServiced: string;
  serviceMKH: number; // miles, kilometers, hours
  primaryPart: string;
  secondaryPart: string;
  thirdPart: string;
  brand: string;
  notes: string;
  updatedBy: string;
  dtstamp: number;
  lat: number = 0;
  long: number = 0;
  duid: string = '';
  oid: string = '';

  constructor(opt?: Partial<IServiceUpdate>) {
    const today = new Date();
    this.id = opt?.id ?? undefined;
    // eslint-disable-next-line deprecation/deprecation
    this.machine = (opt && opt.machine) || '';
    this.machineId = (opt && opt.machineId) ?? undefined;
    this.service = (opt && opt.service) || '';
    this.dateAdded = (opt && opt.dateAdded) || today.toISOString();
    this.dateServiced = (opt && opt.dateServiced) || today.toISOString();
    this.serviceMKH = (opt && opt.serviceMKH) || 0; // miles, kilometers, hours
    this.primaryPart = (opt && opt.primaryPart) || '';
    this.secondaryPart = (opt && opt.secondaryPart) || '';
    this.thirdPart = (opt && opt.thirdPart) || '';
    this.brand = (opt && opt.brand) || '';
    this.notes = (opt && opt.notes) || '';
    this.updatedBy = (opt && opt.updatedBy) || '';
    this.dtstamp = Date.now();
  }
}

export class ServiceEditModel implements IServiceEdit {
  everyMKH: number;
  everyMonths: number;
  dtstamp: number;
  constructor(opt?: any) {
    this.everyMKH = (opt && opt.everyMKH) || 0;
    this.everyMonths = (opt && opt.everyMonths) || 6;
    this.dtstamp = Date.now();
  }
}

export function hasServiceAlert(service: IService): boolean {
  let hasAlert: boolean = false;
  const dateServiced = parseISO(service.dateServiced);

  if (isValid(dateServiced)) {
    const diff = differenceInMonths(new Date(), dateServiced);
    hasAlert = diff >= service.everyMonths;
  }
  return hasAlert;
}
