export enum SortOrderIndex {
  Name = 0,
  DateAdded = 1,
  DatePurchased = 2,
  ServiceDate = 3,
  ModelYear = 4,
}

export const SortOrder = [
  { id: SortOrderIndex.Name, label: 'Name' },
  { id: SortOrderIndex.DateAdded, label: 'Date Added' },
  { id: SortOrderIndex.DatePurchased, label: 'Date Purchased' },
  { id: SortOrderIndex.ServiceDate, label: 'Service Date' },
  { id: SortOrderIndex.ModelYear, label: 'Model Year' },
];

export const CURRENCY_USA = 'USA';

export interface ISettings {
  localDistance: string;
  localCurrency: string;
  sortOrder: SortOrderIndex;
  colorTheme: string;
  colorThemes: string[];
  availServices: string[];
  paidVersionId: string;
  useGeoLocation: boolean;
  dtstamp: number;
  duid: string;
  oid: string;
  pdbConverted: boolean;
}

export interface ISettingsDb {
  localDistance: string;
  localCurrency: string;
  sortOrder: number;
  paidVersionId: string;
  dtstamp: number;
}

export class SettingsModel implements ISettings, ISettingsDb {
  localDistance: string = 'Miles';
  localCurrency: string = CURRENCY_USA;
  sortOrder: number = SortOrderIndex.Name;
  colorTheme: string = 'Dark';
  paidVersionId: string = '';
  dtstamp: number = Date.now();
  duid: string = '';
  oid: string = '';
  useGeoLocation: boolean = false;
  pdbConverted: boolean = false;

  colorThemes: string[] = ['Dark', 'Red', 'Green', 'Blue'];
  availServices: string[] = [
    'Engine Oil/Filter',
    'Transmission Oil/Filter',
    'Air Filter',
    'Coolant',
    'Hydraulic Oil/Filter',
    'Wiper Blades',
    'Fuel Filter',
    'DEF Filter',
    'Cab Filter',
    'Tires',
    'Differential Oil',
    'Transfer Case Oil',
    'Grease',
  ];
}
