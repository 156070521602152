import { inject, Injectable } from '@angular/core';
import { Auth, authState, User } from '@angular/fire/auth';
import {
  Storage,
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
  deleteObject,
} from '@angular/fire/storage';
import {
  objectVal,
  ref as dbRef,
  Database,
  listVal,
  update,
  push,
  remove,
} from '@angular/fire/database';

import { doc, Firestore, docData, updateDoc } from '@angular/fire/firestore';
import { groupBy, omit } from 'lodash-es';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import {
  IImage,
  IMachine,
  IServiceUpdate,
  MachineModel,
  ServiceUpdateModel,
} from './models/models';
import { IProfileDb, ProfileModel } from './models/profile.model';
import { SettingsModel } from './models/settings.model';
import { FirebaseError } from '@angular/fire/app';
import { captureException } from '@sentry/angular';

const getMaxDateServiced = (history: IServiceUpdate[]): string | null =>
  history.reduce<string | null>((max, { dateServiced }) => {
    return max === null || dateServiced > max ? dateServiced : max;
  }, null);

const getMaxServiceMKH = (history: IServiceUpdate[]): number =>
  history.reduce<number>((max, { serviceMKH }) => {
    return max === null || serviceMKH > max ? serviceMKH : max;
  }, 0);

@Injectable({
  providedIn: 'root',
})
export class DataFirebaseService {
  private readonly auth = inject(Auth);
  private readonly db = inject(Database);
  private readonly firestore = inject(Firestore);
  private readonly storage = inject(Storage);

  public readonly user$ = authState(this.auth).pipe(
    filter((user): user is User => !!user),
  );

  public readonly uid$ = this.user$.pipe(
    map((user) => user.uid),
    distinctUntilChanged(),
  );

  private readonly settingsRef$ = this.uid$.pipe(
    map((uid) => dbRef(this.db, `/userProfile/${uid}/settings`)),
  );

  public readonly settings$: Observable<SettingsModel> = this.settingsRef$.pipe(
    switchMap((ref) => objectVal<SettingsModel | null>(ref)),
    filter((settings): settings is SettingsModel => !!settings),
    shareReplay(1),
  );

  public readonly profile$: Observable<ProfileModel> = this.user$.pipe(
    filter((user): user is User => !!user),
    switchMap((user) => {
      return docData(doc(this.firestore, `/userProfile/${user.uid}`)).pipe(
        map((profile) => {
          return new ProfileModel({
            ...profile,
            userId: user.uid,
            emailAddress: user.email ?? '',
          });
        }),
      );
    }),
    shareReplay(1),
  );

  public readonly machines$: Observable<MachineModel[]> = this.uid$.pipe(
    switchMap((uid) =>
      listVal<MachineModel>(dbRef(this.db, `/userProfile/${uid}/machines`), {
        keyField: '_id',
      }),
    ),
    map((machines) => {
      const machineModels = (machines ?? []).map(
        (machine) => new MachineModel(machine),
      );
      return machineModels;
    }),
  );

  public readonly services$: Observable<ServiceUpdateModel[]> = this.uid$.pipe(
    switchMap((uid) =>
      listVal<ServiceUpdateModel>(
        dbRef(this.db, `/userProfile/${uid}/serviceupdates`),
        {
          keyField: 'id',
        },
      ),
    ),
    map((services) => services ?? []),
  );

  public readonly servicesWithIssues$: Observable<ServiceUpdateModel[]> =
    this.services$.pipe(
      map((services) => services.filter((service) => !service.machineId)),
    );

  public getHistory$(
    machineId?: string,
    serviceName?: string,
  ): Observable<IServiceUpdate[]> {
    return this.services$.pipe(
      combineLatestWith(machineId ? this.getMachine$(machineId) : of(null)),
      map(([services, machine]) => {
        if (machine && serviceName) {
          return services.filter((su) => {
            return su.machineId === machine._id && su.service === serviceName;
          });
        } else if (machine && !serviceName) {
          return services.filter((x) => {
            return x.machineId === machine._id;
          });
        } else {
          return services;
        }
      }),
    );
  }

  public async getHistory(
    machineId?: string,
    serviceName?: string,
  ): Promise<IServiceUpdate[]> {
    return await firstValueFrom(this.getHistory$(machineId, serviceName));
  }

  public async getServiceUpdate(id: string): Promise<IServiceUpdate | null> {
    const uid = await firstValueFrom(this.uid$);
    return await firstValueFrom(
      objectVal<IServiceUpdate>(
        dbRef(this.db, `/userProfile/${uid}/serviceupdates/${id}`),
        {
          keyField: 'id',
        },
      ),
    );
  }

  async deleteHistory(serviceUpdate: IServiceUpdate): Promise<void> {
    const uid = await firstValueFrom(this.uid$);
    return remove(
      dbRef(this.db, `/userProfile/${uid}/serviceupdates/${serviceUpdate.id}`),
    );
  }

  public getMachine$(id: string | null): Observable<MachineModel | null> {
    return this.uid$.pipe(
      switchMap((uid) => {
        return objectVal(dbRef(this.db, `/userProfile/${uid}/machines/${id}`), {
          keyField: '_id',
        });
      }),
      map((machine) => {
        return machine ? new MachineModel(machine) : null;
      }),
    );
  }

  public async toggleMachineArchive(
    machineId: string,
    isArchived: boolean,
  ): Promise<void> {
    const uid = await firstValueFrom(this.uid$);

    await update(dbRef(this.db, `/userProfile/${uid}/machines/${machineId}`), {
      isArchived,
    });
  }

  public async deleteMachine(machineId: string): Promise<void> {
    const uid = await firstValueFrom(this.uid$);

    await remove(dbRef(this.db, `/userProfile/${uid}/machines/${machineId}`));
  }

  public async updateProfile(profile: Partial<IProfileDb>): Promise<void> {
    const uid = await firstValueFrom(this.uid$);

    await updateDoc(doc(this.firestore, `/userProfile/${uid}`), profile);
  }

  public async updateSettings(settings: Partial<SettingsModel>): Promise<void> {
    const uid = await firstValueFrom(this.uid$);

    await update(dbRef(this.db, `/userProfile/${uid}/settings`), settings);
  }

  public async updateMachine({
    _id: machineId,
    ...machine
  }: Partial<IMachine> & { _id: string }): Promise<void> {
    const uid = await firstValueFrom(this.uid$);
    const updatedMachine: Partial<IMachine> = {
      ...machine,
    };

    if (machine.purchasePrice) {
      updatedMachine.purchasePrice =
        typeof machine.purchasePrice === 'string'
          ? machine.purchasePrice
          : machine.purchasePrice.toString();
    }

    await update(
      dbRef(this.db, `/userProfile/${uid}/machines/${machineId}`),
      updatedMachine,
    );
  }

  public async addMachine({ _id, ...machine }: IMachine): Promise<void> {
    const uid = await firstValueFrom(this.uid$);
    const updatedMachine: Omit<IMachine, '_id'> = {
      ...machine,
      purchasePrice:
        typeof machine.purchasePrice === 'string'
          ? machine.purchasePrice
          : machine.purchasePrice.toString(),
    };

    await push(dbRef(this.db, `/userProfile/${uid}/machines`), updatedMachine);
  }

  public async addImageToMachine(
    machineId: string,
    image: File,
  ): Promise<void> {
    const uid = await firstValueFrom(this.uid$);
    const randomId = Math.random().toString(36).substring(2);
    const fileName = `${randomId}.${image.name.split('.').pop()}`;
    const filePath = `${uid}/${machineId}/${fileName}`;
    const ref = storageRef(this.storage, filePath);
    await uploadBytes(ref, image);
    const downloadUrl = await getDownloadURL(ref);

    await push(
      dbRef(this.db, `/userProfile/${uid}/machines/${machineId}/images`),
      {
        url: downloadUrl,
        ref: ref.toString(),
      },
    );
  }

  public async deleteImageFromMachine(
    machineId: string,
    imageId: string,
  ): Promise<void> {
    const uid = await firstValueFrom(this.uid$);

    const ref = dbRef(
      this.db,
      `/userProfile/${uid}/machines/${machineId}/images/${imageId}`,
    );

    const image = await firstValueFrom(objectVal<IImage | null>(ref));

    if (!image) {
      return;
    }

    try {
      await deleteObject(storageRef(this.storage, image.ref));
    } catch (e) {
      if (e instanceof FirebaseError && e.code === 'storage/object-not-found') {
        captureException(e);
      } else {
        throw e;
      }
    }

    await remove(ref);
  }

  public async addServiceUpdate(
    machineId: string,
    serviceUpdate: IServiceUpdate,
  ): Promise<void> {
    const [uid, machine] = await Promise.all([
      firstValueFrom(this.uid$),
      firstValueFrom(this.getMachine$(machineId)),
    ]);

    if (!machine) {
      console.error(`Machine ${machineId} not found`);
      return;
    }

    const serviceUpdateForDb = omit(
      {
        ...serviceUpdate,
        dtstamp: Date.now(),
        machineId: machine._id,
      },
      ['machine'],
    );

    if (serviceUpdate.id) {
      await update(
        dbRef(
          this.db,
          `/userProfile/${uid}/serviceupdates/${serviceUpdate.id}`,
        ),
        serviceUpdateForDb,
      );
    } else {
      await push(
        dbRef(this.db, `/userProfile/${uid}/serviceupdates`),
        omit(serviceUpdateForDb, ['id']),
      );
    }

    await this.syncMachineWithServiceUpdates(machineId);
  }

  public async syncMachineWithServiceUpdates(machineId: string): Promise<void> {
    const [machine, history] = await Promise.all([
      firstValueFrom(this.getMachine$(machineId)),
      firstValueFrom(this.getHistory$(machineId)),
    ]);

    if (!machine) {
      console.error(`Machine ${machineId} not found`);
      return;
    }

    const maxDateServiced = getMaxDateServiced(history);
    const maxServiceMKH = getMaxServiceMKH(history);

    machine.lastServiced = maxDateServiced;
    machine.lastMKH = maxServiceMKH;

    Object.entries(groupBy(history, ({ service }) => service)).forEach(
      ([serviceName, serviceUpdates]) => {
        const service = machine.allServiceItems.find(
          (s) => s.name === serviceName,
        );

        if (!service) {
          console.warn(
            `Service ${serviceName} not found on machine ${machineId}`,
          );
          return;
        }

        const maxDateServiced = getMaxDateServiced(serviceUpdates);

        if (maxDateServiced) {
          service.dateServiced = maxDateServiced;
        }

        service.serviceMKH = getMaxServiceMKH(serviceUpdates);
      },
    );

    await this.updateMachine(machine);
  }

  public async addFCMToken(token: string): Promise<void> {
    const uid = await firstValueFrom(this.uid$);
    const tokenMap = await firstValueFrom(
      objectVal<{ [fcmToken: string]: boolean }>(
        dbRef(this.db, `/userProfile/${uid}/fcmTokens`),
      ),
    );

    await update(dbRef(this.db, `/userProfile/${uid}/fcmTokens`), {
      ...tokenMap,
      [token]: true,
    });
  }

  constructor() {
    this.settingsRef$
      .pipe(
        switchMap((ref) => objectVal<SettingsModel | null>(ref)),
        filter((settings): settings is null => settings === null),
      )
      .subscribe(() => {
        this.updateSettings(new SettingsModel());
      });
  }
}
